export default () => {
	return {
		activeIndex: '1',//顶部类型
		activeNames: [],//折叠面板当前展开层级
		// modifyList:[],//修改过菜单权限的面板id集合
		roleList:[],//角色列表
		roleListBackup: [],//角色列表备份数据
		menuList:[],//角色菜单列表
		modifyName:'',//修改的角色名称
		roleName:'',//新建角色名称
		newLv:null,//新建角色等级
		newMaxLv: [],//新建角色的最高等级
		newTask: 0,//是否允许角色在任务管理里面新建任务(新建角色)
		newCheckList: [],//新增角色选中菜单
		checkList: [],//角色列表选中菜单
		ops: {
			bar: {
				onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
				keepShow: true, //滚动条一直显示
			}
		}, //滚动条设置
		listBackground: ['backgroundColor: rgba(223, 239, 255, 1);',
			'backgroundColor: rgba(255, 240, 217, 1);',
			'backgroundColor: rgba(200, 255, 207, 1);'
		], // 角色列表背景颜色
	}
}
