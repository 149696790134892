<template>
	<!-- 角色 -->
	<div class="role" @click="closeEdit">
		<div class="role-main">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
			  <el-menu-item index="1">测振平台</el-menu-item>
			  <el-menu-item index="2">小程序</el-menu-item>
			  <el-menu-item index="3">转发平台</el-menu-item>
			  <el-menu-item index="4">监管平台</el-menu-item>
			  <el-menu-item index="5">云平台2.0</el-menu-item>
			</el-menu>
			<p class="tips-txt">注：点击角色名称可以修改角色名</p>
			<div class="but-box"><el-button @click="open('add-role')" type="primary" icon="el-icon-plus" size="medium" circle></el-button></div>
			<div class="scroll-box">
			<vue-scroll>
				<div class="cell-content">
					<div class="cell" v-for="(val, index) in roleList" :key="index">
						<div class="show-off">
							<el-collapse :value="activeNames" v-for="(value, len) in val.children" :key="len">
								<el-collapse-item :name="value.id">
									<template slot="title">
										<div class="collapse-title" @click.stop="foldHandleChange($event, value.id)" :style="listBackground[index % 3]">
											<span class="collapseName" @click.stop="Edit($event, value)">{{ value.name }}</span>
											<div class="edit-input" @click.stop="">
												<el-input :placeholder="value.name" v-model="modifyName">
													<el-button slot="append" type="primary" size="mini" @click="setName($event, value.id)">修改</el-button>
												</el-input>
											</div>
											<el-button @click.stop="roleDelete(value)" type="primary" icon="el-icon-delete" size="mini"></el-button>
										</div>
									</template>
									<div class="collapse-content">
										<div class="content">
											<!-- <div class="module switch">
												<span class="title">新建任务：</span>
												<el-switch v-model="value.ctk" active-text="允许" inactive-text="禁止" :active-value="1" :inactive-value="0"></el-switch>
											</div> -->
											<ul class="parent-ul" v-for="(i, inde) in value.children" :key="inde">
												<li class="parent-li">
													<el-checkbox v-model="i.bd" @change="handleCheckAllChange($event, i, 'edit')">{{ i.name }}</el-checkbox>
												</li>
												<li :class=" i.tg == 1 ? 'child-li colum-class' : 'child-li row-class'"> 
												<!--  -->
													<ul class="child-ul colum-class" v-for="(item, ind) in i.children" :key="ind">
														<li>
															<el-checkbox v-model="item.bd" @change="handleCheckedChange($event, i, 'edit')">{{ item.name }}</el-checkbox>
														</li>
														<li class="childs-ul">
															<ul v-for="(val, inds) in item.children" :key="inds">
																<li>
																	<el-checkbox v-model="val.bd" @change="handleChange($event, i, item, 'edit')">{{ val.name }}</el-checkbox>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											</ul>
										</div>
										<div class="button-box">
											<!-- <el-button type="primary" size="mini">编辑</el-button> -->
											<el-button type="primary" size="mini" @click="setAuthority(value)">确定</el-button>
											<!-- <el-button type="info" size="mini">取消</el-button> -->
										</div>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>
						<!-- 内容标注区 -->
						<div class="label-box">
							<div class="tips">
								<div class="logo-img">
									<div class="background-box" :style="{ top: 100 - val.ratio + '%' }"></div>
									<!-- <div class="background-box" :style="{top:val * 6 + 'px'}"></div> -->
									<img src="../../../assets/images/roleManagerlevel.png" width="40" height="32" />
								</div>
								<span>{{ val.lv }}级权限</span>
							</div>
						</div>
					</div>
				</div>
			</vue-scroll>
			</div>
		</div>
		<!-- 添加角色 -->
		<div class="add-role Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>新增角色</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-role')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<div class="module">
							<span class="title"><i>*</i>&nbsp;&nbsp;角色名&nbsp;&nbsp;</span>
							<el-input v-model="roleName" placeholder="请输入角色名"></el-input>
						</div>
						<div class="module">
							<span class="title"><i>*</i>&nbsp;&nbsp;权限等级&nbsp;&nbsp;</span>
							<el-select v-model="newLv" placeholder="请选择角色等级">
								<el-option v-for="(item, ind) in newMaxLv" :key="ind" :label="item + '级权限'" :value="item"></el-option>
							</el-select>
						</div>
						<!-- <div class="module switch">
							<span class="title">新建任务：</span>
							<el-switch v-model="newTask" active-text="允许" inactive-text="禁止" :active-value="1" :inactive-value="0"></el-switch>
						</div> -->
					</div>
					<div class="cell-scroll">
						<vue-scroll>
							<div class="cell column">
								<span class="title"><i>*</i></span>
								<ul class="parent-ul" v-for="(i, inde) in menuList" :key="inde">
									<li class="parent-li">
										<el-checkbox v-model="i.bd" @change="handleCheckAllChange($event, i, 'add')">{{ i.name }}</el-checkbox>
									</li>
									<li :class=" i.tg == 1 ? 'child-li colum-class' : 'child-li row-class'">
										<ul class="child-ul colum-class" v-for="(item, ind) in i.children" :key="ind">
											<li>
												<el-checkbox v-model="item.bd" @change="handleCheckedChange($event, i, 'add')">{{ item.name }}</el-checkbox>
											</li>
											<li>
												<ul class="childs-ul">
													<li v-for="(val, inds) in item.children" :key="inds">
														<el-checkbox v-model="val.bd" @change="handleChange($event, i, item, 'add')">{{ val.name }}</el-checkbox>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</vue-scroll>
					</div>
					<div class="button-box"><el-button :disabled="roleName == '' || newLv == null" type="primary" size="mini" @click='addRole'>确认</el-button></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import role from './role.js';
export default role;
</script>

<style scoped lang="scss">
@import './role.scss';
</style>
