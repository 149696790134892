import data from "./roleData.js";

export default {
	name: "role",
	data,
	created() {
		this.getList();
	},
	mounted() {},
	computed: {
		listCtk() {
			return (val) => {
				var status = false;
				if (val == 1) {
					status = true;
				}
				return status
			}
		}
	},
	methods: {
		// 角色列表获取
		getList() {
			var data = {
				type: this.activeIndex
			}
			this.axios.post('web2/role/odnr/grlist',data).then((res) => {
				this.roleList = [];
				this.roleListBackup = [];
				if (res.status) {
					this.roleList = this.deepCopy(res.data);
					this.roleListBackup = this.deepCopy(res.data);
				}
			})
		},
		// 角色菜单列表获取
		getMenu() {
			var data = {
				type: this.activeIndex
			}
			this.axios.post('web2/role/mad/grr',data).then((res) => {
				this.newMaxLv = [];
				if (res.status) {
					this.menuList = res.data.list;
					// // console.log("菜单")
					// // console.log(res.data);
					for(let i = res.data.lv + 1; i <= res.data.lv + 8; i++){
						this.newMaxLv.push(i);
						// console.log(this.newMaxLv)
					}
				}
			})
		},
		// 新增角色
		addRole() {
			var data = {
				name: this.roleName,
				ids: this.newCheckList.toString(),
				// ctk: this.newTask,
				level: this.newLv
			};
			this.axios.post('web2/role/mad/add', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '角色创建成功',
						type: 'success'
					});
					this.close('add-role');
					this.getList();
				}
			})
		},
		// 角色名称修改
		setName(e, id) {
			var data = {
				id,
				name: this.modifyName
			};
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var close = false;
			const obj = $(e.currentTarget).parent().parent().parent();
			this.axios.post('web2/role/mad/mdi', data).then((res) => {
				close = true;
				loading.close();
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功',
						type: 'success'
					});
					// console.log();
					obj.hide();
					obj.prev().show();
					this.getList();
				}
			});
			setTimeout(() => {
				if (!close) {
					loading.close();
				}
			}, 4000);
		},
		// 角色权限修改
		setAuthority(val) {
			// // console.log(val)
			this.checkList = [];
			val.children.forEach((item) => {
				if (item.bd) {
					this.checkList.push(item.id);
				}
				item.children.forEach((val) => {
					if (val.bd) {
						this.checkList.push(val.id);
					}
					val.children.forEach((value) => {
						if (value.bd) {
							this.checkList.push(value.id);
						}
					})
				})
			})
			var data = {
				id: val.id,
				ids: this.checkList.toString(),
				type: this.activeIndex
				// ctk: val.ctk
			};
			this.axios.post('web2/role/mad/mrr', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功',
						type: 'success'
					});
					this.getList();
				}
			})
		},
		// 角色删除
		roleDelete(val) {
			this.$confirm('此操作将删除<strong>'+ val.name +'</strong>，是否继续?', '警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true,
					dangerouslyUseHTMLString: true
				})
				.then(() => {
					var data = {
						id: val.id
					};
					this.axios.post('web2/role/mad/del', data).then((res) => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList();
						}
					})
				})
				.catch(() => {});
		},
		// 顶部类型切换
		handleSelect(key, keyPath) {
		    // // console.log(key, keyPath);
			this.activeIndex = keyPath[0];
			this.getList();
		},
		// 角色名称编辑
		Edit(e, data) {
			$('.collapseName').show();
			$('.edit-input').hide();
			this.modifyName = data.name;
			$(e.currentTarget).hide();
			$(e.currentTarget).next().show();
			// e.stopPropagation();
		},
		// 直接全选 
		handleCheckAllChange(status, data, value, id = null) {
			data.children.forEach((item) => {
				if (!status) {
					item.bd = false;
				}
				item.children.forEach((val) => {
					if (!item.bd) {
						val.bd = false;
					}
				})
			});
			if (value == 'add') {
				var exist = this.newCheckList.indexOf(data.id);
				if (data.bd) {
					if (exist == -1) {
						this.newCheckList.push(data.id);
					}
				} else {
					if (exist != -1) {
						this.newCheckList.splice(exist, 1);
					}
				}
			} else if (value == 'edit') {}
			
			// console.log(this.newCheckList)
		},
		// 第二级选中
		handleCheckedChange(status, data, value, id = null) {
			data.children.forEach((item) => {
				if (item.bd) {
					data.bd = true;
				} else {
					item.children.forEach((val) => {
						if (!item.bd) {
							val.bd = false;
						}
					})
				}
			});
			if (value == 'add') {
				var dataexist = this.newCheckList.indexOf(data.id);
				if (data.bd) {
					if (dataexist == -1) {
						this.newCheckList.push(data.id);
					}
				} else {
					if (dataexist != -1) {
						this.newCheckList.splice(dataexist, 1);
					}
				}
				data.children.forEach((item) => {
					var exist = this.newCheckList.indexOf(item.id);
					if (item.bd) {
						// data.bd = true;
						if (exist == -1) {
							this.newCheckList.push(item.id);
						}
					} else {
						if (exist != -1) {
							this.newCheckList.splice(exist, 1);
						}
					}
				})
			} else if (value == 'edit') {}

			// console.log(this.newCheckList)

		},
		// 第三级选中
		handleChange(status, parent, data, value, id = null) {
			if (status) {
				parent.bd = true;
			}

			data.children.forEach((item) => {
				if (item.bd) {
					data.bd = true;
				}
			});
			if (value == 'add') {
				var dataexist = this.newCheckList.indexOf(data.id);
				if (data.bd) {
					if (dataexist == -1) {
						this.newCheckList.push(data.id);
					}
				} else {
					if (dataexist != -1) {
						this.newCheckList.splice(dataexist, 1);
					}
				}
				
				data.children.forEach((item) => {
					var exist = this.newCheckList.indexOf(item.id);
					if (item.bd) {
						// data.bd = true;
						if (exist == -1) {
							this.newCheckList.push(item.id);
						}
					} else {
						if (exist != -1) {
							this.newCheckList.splice(exist, 1);
						}
					}
				});
				
				var ind = this.newCheckList.indexOf(parent.id);
				if (parent.bd) {
					if (ind == -1) {
						this.newCheckList.push(parent.id);
					}
				} else {
					if (ind != -1) {
						this.newCheckList.splice(ind, 1);
					}
				}
			} else if (value == 'edit') {}

			// console.log(this.newCheckList)

		},
		// 打开弹窗
		open(val, status) {
			if (val == "add-role") {
				this.getMenu();
			}
			$('.' + val).show();
			this.status = status;

		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();
			this.newCheckList = []; //新增角色选中菜单
			this.roleName = ''; //新建角色名称
			this.newLv = null; //新建角色等级
			this.newMaxLv = []; //新建角色的最高等级
			this.newTask = 0; //是否允许角色在任务管理里面新建任务(新建角色)
		},
		// 角色页面点击关闭列表名称修改
		closeEdit(e) {
			$(e.currentTarget).find('.collapseName').show();
			$(e.currentTarget).find('.edit-input').hide();
		},
		// 折叠面板点击关闭恢复页面数据
		foldHandleChange(e, val) {
			// // console.log(val);
			// // console.log(this.activeNames);
			var index = this.activeNames.indexOf(val);
			if (index == -1) {
				this.activeNames.push(val);
				this.closeEdit(e);
			} else {
				if ($(e.currentTarget).find(".edit-input").is(':hidden')) {
					//如果隐藏时。。。
					// this.$confirm('关闭当前面板将清除当前你所做的更改，是否继续关闭?', '警告', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning',
					// 	center: true
					// })
					// .then(() => {
					// 	this.roleList = this.roleListBackup;
					// 	// console.log(this.roleList)
					// 	// console.log(this.roleListBackup)
					// 	this.activeNames.splice(index, 1);
					// })
					// .catch(() => {});
					this.activeNames.splice(index, 1);
					this.roleList = this.roleListBackup;
				} else {
					//如果显示时。。。
					this.closeEdit(e);
				}

			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
}
